import { useState, useEffect, useRef } from 'react';
import './Table.css';

export function Table(props) {
    const { value, onChange, readOnly } = props

    const [activeCell, setActiveCell] = useState({ i: 0, j: 0 })
    const [selectedRange, setSelectedRange] = useState([{ i0: null, j0: null, i1: null, j1: null}])
    
    
    function setTableItem(row_ind, col_ind, val) {
        onChange(value.map((row, i) => row.map((col, j) => row_ind === i && col_ind === j ? val : value[i][j])))
    }

    function appendTableRow(){
        onChange([...value, [[],[]]])
    }

    function handlePaste(row_ind, e) {
        e.preventDefault();
       
        let data = e.clipboardData.getData('text').split('\n').map(row => row.split('\t'))
        onChange(value.slice(0, row_ind).concat(data))
    }
    
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current && inputRef.current.focus();
    }, [activeCell])

    return <table onBlur={e => setActiveCell({i:null, j:null})}>
        <tbody >
            <tr>
                <th>X (longitude)</th>
                <th>Y (latitude)</th>
            </tr>
            {value.map((row, i) =>
                <tr key={i}>
                    {row.map((cell, j) =>
                        <td 
                            onMouseDown={e => setSelectedRange({...selectedRange, i0:i, j0:j})}
                            onMouseUp={e => {
                                if(selectedRange.i0 === i && selectedRange.j0 === j){
                                    setActiveCell({ i, j });
                                }
                                else {
                                    setSelectedRange({...selectedRange, i1:i, j1:j});
                                }
                            }}
                            key={j}
                            style={{overflow: 'hidden'}}
                        >
                            {(i === activeCell.i && j === activeCell.j && !readOnly) ?
                                <input 
                                    className='table-input'
                                    type="text"
                                    ref={inputRef}
                                    onPaste={e => handlePaste(i, e)} 
                                    style={{ padding: ".5rem", textAlign: "left", width: "100%", height: "100%" }} 
                                    onChange={e => setTableItem(i, j, e.target.value)} value={cell} 
                                />
                                : cell
                            }
                        </td>
                    )}
                </tr>
            )}
            {
                !readOnly && <tr onClick={appendTableRow}>
                    <td style={{borderColor: '#DDDD'}}></td>
                    <td style={{borderColor: '#DDDD'}}></td>
                </tr>
            }
        </tbody>
    </table>

}