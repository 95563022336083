export function Card(props){
    const { style } = props
    return <div style={{
        padding: '1rem',
        border: '1px solid gray',
        borderRadius: '0.5rem',
        boxShadow: '1rem 1rem 1rem #2222',
        ...style
    }}>
        {props.children}
    </div>
}