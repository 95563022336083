import { useState } from 'react';
import { Transformer } from './components/Transformer';
import { Map } from './components/Map';
import './App.css';
import { Card } from './components/Card';

function App() {
  const [ mapCoords, setMapCoords ] = useState([]);
  return (
    <div className="App">
      <Card style={{
        position: 'absolute', 
        margin: '1rem',
        zIndex: 10000, 
        backgroundColor: 'white',
        width: '40%',
        maxHeight: '90vh',
        overflowY: 'auto',
      }}>
        <h2 style={{textAlign: 'center'}}>Coordinate Transformer <span style={{ fontSize: '.8rem'}}>by <a href="https://gulyamani.com">gulyamani</a></span></h2>
        <p>You can use SRIDs, strings (<code>'EPSG:3857'</code>, <code>'SR-ORG:1234'</code>), proj4 strings (<code>'+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=5...'</code>), Well-Known Text (<code>PROJCS["PakI",GEOGCS["GCS_Everest_def_1962",...</code>) for input and output CRS fields.</p>
        <p>You can use <a href="https://spatialreference.org/" rel="noreferrer" target="_blank">spatialreference.org</a> to get CRS Reference strings.</p>
        <Transformer onCoordsChange={setMapCoords}/>
      </Card>
      <Map coords={mapCoords} style={{ height: '100vh'}}/>
    </div>
  );
}

export default App;
