import { useState } from 'react';
import { Table } from './Table';
import { CRS } from './CRS';


const APIUrl = 'https://coords-transform.fly.dev/transform'
//const APIUrl = 'http://localhost:8000/transform'

export function Transformer(props) {
    const [inputCRS, setInputCRS] = useState('')
    const [error, setError] = useState('')
    const [outputCRS, setOutputCRS] = useState('EPSG:4326')
    const [inputData, setInputData] = useState([[[], []]])
    const [outputData, setOutputData] = useState([[[], []]])
    const [submitting, setSubmitting] = useState(false)

    const { onCoordsChange } = props

    function inputIsValid(input) {
        if (
            !Array.isArray(input) ||
            input.length === 0 ||
            !Array.isArray(input[0]) ||
            input[0].length !== 2
        ) {
            return false
        }
        if (!input.reduce((acc, row) => acc & !isNaN(row[0]) && !isNaN(row[1]) && row[0] && row[1], true)){
            return false
        }
        return true
    }

    function buttonDisabled(){
        return submitting || !inputCRS || !inputIsValid(inputData) 
    }

    async function SubmitInput() {
        setSubmitting(true)
        setError('')
        if (!inputIsValid(inputData) || !inputCRS || !outputCRS) {
            return
        }
        const response = await fetch(APIUrl, {
            method: 'POST',
            body: JSON.stringify({
                coordinates: inputData,
                crs_from: inputCRS,
                crs_to: outputCRS,
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
        setSubmitting(false)
        if(response.status !== 200){
            const text = await response.text()
            setError(text)
            
            return;
        }
        const data = await response.json()
        if (Array.isArray(data.coordinates)) {
            setOutputData(data.coordinates)
            if(outputCRS.toLowerCase() !== "epsg:4326" || outputCRS.toLowerCase() !== "4326"){
                onCoordsChange(data.coordinates)
            }
        }
    }

    async function Fetch4326() {
        if (!inputIsValid(inputData) || !inputCRS) {
            return
        }
        const response = await fetch(APIUrl, {
            method: 'POST',
            body: JSON.stringify({
                coordinates: inputData,
                crs_from: inputCRS,
                crs_to: "4326",
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
        const data = await response.json()
        if (Array.isArray(data.coordinates)) {
            onCoordsChange(data.coordinates)
        }
    }

    function handleSubmit() {
        SubmitInput();
        if(outputCRS.toLowerCase() !== "epsg:4326" || outputCRS.toLowerCase() !== "4326"){
            Fetch4326();
        }
    }

    return <>
        <div style={{ display: 'flex', width: '100%', alignContent: 'space-between', margin: '1rem 0' }}>
            <div style={{ marginRight: '1rem', width: '100%' }}>
                <CRS placeholder="e.g. 'EPSG:3857', 'SR-ORG:1234', ..." value={inputCRS} label="Input CRS" onChange={e => setInputCRS(e.target.value)} />
            </div>
            <div style={{ marginLeft: '1rem', width: '100%' }}>
                <CRS placeholder="Output CRS" value={outputCRS} label="Output CRS" onChange={e => setOutputCRS(e.target.value)} />
            </div>
        </div>
        <button style={{ cursor: buttonDisabled() ? 'not-allowed' : 'pointer', color: 'white', backgroundColor: buttonDisabled() ? 'gray' : '#449', paddingBottom: '.4rem', paddingTop: '.4rem', width: '100%' }} disabled={buttonDisabled()} onClick={handleSubmit}>Submit</button>
        <div style={{color:'red',  marginBottom: '1rem',  textAlign: 'center'}}>{error ? `Error: ${error}` : ''}</div>
        <div style={{ display: "flex" }}>
            <div style={{ marginRight: '1rem' }}>
                <Table value={inputData} onChange={setInputData} />
            </div>
            <div style={{ marginLeft: '1rem' }}>
                <Table value={outputData} onChange={setOutputData} readOnly={true} />
            </div>
        </div>
    </>
}